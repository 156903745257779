.OurWorks{
    background-color: rgb(36 36 36 / 60%);
    padding: 150px 0;
    
}

.Title{
    letter-spacing: 21px;
    text-transform: uppercase;
    color: var(--MainColor);
    font-family: 'Poppins SemiBold';
    margin: 0 0 115px;
    font-size: 22px;
}

.counter{
    display: inline-block;
    vertical-align: middle;
    min-width: 333px;
}
.counter h4 span,
.counter h4{
    font-size: 82px;
    font-family: 'Poppins SemiBold';
    color: #1d1d1d;
    filter: drop-shadow(0px 0px .6px #888);
}

.counter span{
    font-size: 26px;
    font-family: 'Poppins Medium';
    color: #979797;
}

.viewallBtn{
    align-self: flex-end;
    text-align: right;
}

.viewallBtn h4{
    margin: 0;
    font-size: 22px;
    letter-spacing: 18px;
    color: #979797;
    font-family: 'Poppins SemiBold';
    cursor: pointer;
    user-select: none;
}

.item{
    display: flex;
    align-items: center;
    position: relative;
}

.SliderWrap{
    margin-top: 100px;
    position: relative;
}

.sliderTitleContent{
    padding-left: 100px;
    flex-grow: 1;
    max-width: 70%;
    position: relative;
}

.sliderTitleContent span{
    font-size: 28px;
    color: #979797;
    margin-bottom: 40px;
    display: block;
    font-family: 'Poppins SemiBold';
}

.sliderTitleContent h4{
    font-family: 'Poppins SemiBold';
    font-size: 117px;
    color: #fff;
    margin-bottom: 40px;
    line-height: 1;
}

.sliderTitleContent p{
    font-size: 19px;
    color: #979797;
    max-width: 80%;
}
.customDot{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.customDot ul{
    margin: 0;
    padding: 0;
}

.customDot ul li{
    display: block;
    margin-bottom: 35px;
    cursor: pointer;
    font-size: 16px;
    transition: all 2s ease-in-out;
    position: relative;
}

.customDot ul li:last-child{
    margin: 0;
}

.SliderImage{
    display: block;
    flex-shrink: 0;
}

.LeftArrow{
    right: calc(20% + 30px);
    left: auto;
    bottom: -50px;
    top: auto;
}

.RightArrow{
    right: calc(17% - 30px);
    left: auto;
    bottom: -50px;
    top: auto;
}

.SlideCount{
    position: absolute;
    right: 18.3%;
    bottom: -40px;
    font-size: 15px;
}

.BgText{
    font-size: 300px;
    position: absolute;
    font-family: 'Poppins SemiBold';
    color: #232323;
    top: 50%;
    transform: translateY(-50%);
}

/* .CaseStudyImg{
    position: absolute;
    right: 10%;
    bottom: -15%;
    cursor: pointer;
} */
@media(max-width:1280px){
    .BgText{
        font-size: 220px;
    }
}
@media(max-width:1024px){
    .OurWorks{
        padding: 100px 0;
    }
    .Title{
        margin: 0 0 25px;
    }
    .counter{
        min-width: 285px;
    }
    .counter h4 span,
    .counter h4{
        font-size: 65px;
    }
    .sliderTitleContent{
        flex: 1 1 60%;
        padding: 30px 0 30px 60px;
    }
    .SliderImage{
        flex: 1 1 40%;
    }
    .sliderTitleContent span{
        margin-bottom: 20px;
    }
    .sliderTitleContent h4{
        font-size: 74px;
        margin-bottom: 20px;
    }
    .sliderTitleContent p{
        max-width: 70%;
    }
    .CaseStudyImg{
        transform: scale(0.6);
        right: 0%;
        bottom: -25%;
    }
    .BgText{
        font-size: 200px;
    }
}
@media(max-width:991px){
    .BgText{
        font-size: 150px;
    }
    .sliderTitleContent{
        padding: 0;
        padding-left: 50px;
        max-width: 60%;
    }
    .sliderTitleContent span{
        font-size: 20px;
        margin-bottom: 20px;
    }
    .sliderTitleContent h4{
        font-size: 50px;
        margin-bottom: 20px;
    }
    .sliderTitleContent p{
        max-width: unset;
        font-size: 16px;
    }
    .customDot :global(.slick-active){
        left: -10px;
    }
    .CaseStudyImg{
        right: 5%;
        bottom: -55px;
        transform: scale(0.5);
        transform-origin: right bottom;
    }
    .counter{
        min-width: auto;
        width: 50%;
    }
    .counter h4 span,
    .counter h4{
        font-size: 55px;
    }
    .counter span{
        font-size: 22px;
    }
    .viewallBtn{
        font-size: 17px;
        letter-spacing: 12px;
    }
    .Title{
        margin: 0 0 35px;
        letter-spacing: 15px;
    }
    .SliderWrap{
        margin-top: 60px;
    }
    .OurWorks{
        padding: 100px 0;
    }
}
@media(max-width:767px){
    .OurWorks{
        padding: 50px 0;
    }
    .Title{
        letter-spacing: 8px;
        margin: 0 0 35px;
    }
    .counter h4 span,
    .counter h4{
        font-size: 40px;
        margin: 0;
    }
    .counter{
        margin-bottom: 25px;
    }
    .counter span{
        font-size: 16px;
    }
    .viewallBtn{
        text-align: left;
    }
    .viewallBtn h4{
        font-size: 14px;
        letter-spacing: 6px;
    }
    .item{
        flex-direction: column;
    }
    .sliderTitleContent{
        padding-left: 40px;
        margin-bottom: 40px;
    }
    .sliderTitleContent span{
        font-size: 16px;
        margin-bottom: 15px;
    }
    .sliderTitleContent h4{
        font-size: 35px;
        margin-bottom: 10px;
    }
    .sliderTitleContent p{
        font-size: 17px;
    }
    .CaseStudyImg {
        display: none;
    }
    .BgText{
        font-size: 100px;
        top: 0;
        transform: unset;
    }
    .customDot{
        top: 0;
        transform: none;
    }
    .customDot ul li{
        margin-bottom: 18px;
        font-size: 14px;
    }
    .customDot :global(.slick-active){
        left: -10px;
    }
    .SlideCount{
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    .LeftArrow{
        right: auto;
        left: 35%;
        z-index: 9;
    }
    .RightArrow{
        right: 35%;
        left: auto;
        text-align: right;
        z-index: 9;
    }
}
@media(max-width:575px){
    .sliderTitleContent{
        max-width: 90%;
        padding-left: 20px;
    }
}