.mainWrapper {
    background-color: #1A1A1A;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainTitle {
    font-size: 24px;
    color: var(--MainColor);
}

.subHeader {
    font-size: 20px;
    color: var(--MainColor);
}

.containText {
    font-size: 16px;
}

.servicesContainer {
    padding: 20px;
}

.logoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}