.footer{
    padding: 130px 0 100px 0;
}

.FooterLogo{
    display: block;
}

.Address{
    font-size: 18px;
    font-family: 'Poppins Light';
    margin: 20px 0 0 0 ;
    color: #979797;
}

.FooterTitle{
    color: #979797;
    font-size: 20px;
    font-family: 'Poppins SemiBold';
    margin-bottom: 25px;
}

.FooterLinks{
    margin: 0;
    padding: 0;
}

.FooterLinks li{
    display: block;
    margin-bottom: 14px;
    cursor: pointer;
}
.FooterLinks li:last-child{
    margin-bottom: 0;
}

.Copyright{
    border-top: 1px solid #3f3f3f;
    padding: 70px 0;
}

.CopyrightText{
    font-size: 19px;
    color: #979797;
    font-family: 'Poppins Light';
}

.CopyrightLink{
    text-align: left;
    display: block;
    margin: 0;
    padding: 0;
}

.CopyrightLink li{
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    color: #979797;
    cursor: pointer;
}

.CopyrightLink li:not(:last-child):after{
    content: '';
    height: 15px;
    width: 1px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
    background-color: #979797;
}

.socialIcon{
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.footerBottomRight{
    text-align: right;
}

.CopyrightSociaLink{
    text-align: left;
}

.CopyrightSociaLink img:hover{
    filter: brightness(0) saturate(100%) invert(88%) sepia(25%) saturate(3681%) hue-rotate(336deg) brightness(103%) contrast(103%);
}

.CopyrightSociaLink img ~ img{
    margin-left: 30px;
}

@media(max-width:1024px){
    .Address{
        font-size: 16px;
    }
    .Copyright{
        padding: 50px 0;
    }
}
@media(min-width:992px){
    .rightAlign{
        text-align: right;
    }
    .RightLinks{
        display: inline-block;
        text-align: left;
    }
    .CenterdLinks{
        text-align: left;
        display: inline-block;
    }
    
    .centerAlign{
        text-align: center;
    }
    
    .RightAlign{
        text-align: right;
    }
}
@media(max-width:991px){
    .footer{
        padding: 90px 0 60px 0;
    }
    .FooterLogo{
        margin-bottom: 35px;
    }
    .Copyright{
        padding: 40px 0;
    }
}
@media(max-width:767px){
    .footer{
        padding: 90px 0 60px 0;
    }
    .rightAlign,
    .RightAlign{
        margin-top: 35px;
    }
    
    .footerBottomRight{
        text-align: center;
        margin-top: 30px;
    }
    .CopyrightText{
        text-align: center;
        font-size: 16px;
    }
    .CopyrightLink{
        text-align: center;
    }
    .Copyright{
        padding: 30px 0;
    }
    .CopyrightSociaLink img{
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
    .CopyrightLink li{
        font-size: 16px;
    }
}

@media(max-width:575px){
    .Address{
        font-size: 16px;
        margin: 10px 0 0 0;
    }
  
    .CenterdLinks{
        margin-bottom: 35px;
    }
    .FooterLinks li{
        font-size: 16px;
    }
    .FooterTitle{
        margin-bottom: 15px;
    }
    
}