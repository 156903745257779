.header{
    position: relative;
    padding: 35px 0;
}
.container{
    max-width: 1830px;
    padding: 0;
}
.ButtonPrimery{
    position: relative;
    background-color: var(--MainColor);
    border-radius: 25px;
    border: none;
    outline: none;
    padding: 15px 30px;
    color: #000;
    font-family: 'Poppins Regular';
    line-height: 1;
    z-index: 2;
    overflow: hidden;
}
.ButtonPrimery::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    /* border-radius: 25px; */
    bottom: 0;
    background: #242424;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.ButtonPrimery:hover::before{
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    bottom: 0;
}
.ButtonPrimery:hover{
    background-color: transparent;
}
.navbarRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.navbarRight ul{
    display: flex;
    align-items: center;
    margin: 0 60px 0 0;
    padding: 0;
}
.navbarRight ul li{
    display: block;
    margin-left: 60px;
    position: relative;
    cursor: pointer;
}
.navbarRight ul li:after{
    content: '';
    width: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 2px;
    background-color: var(--MainColor);
    margin: 0 auto;
    transition: all .3s cubic-bezier(0.52, 0.64, 0.37, 0.66);
}
.navbarRight ul li:hover:after{
    width: 100%;
}
.MenuIcon{
    height: 40px;
    width: 40px;
    background-color: transparent;
    border: 1px solid var(--MainColor);
    box-shadow: none;
    position: relative;
    padding: 0;
}
.MenuIcon span{
    width: 23px;
    height: 1px;
    display: block;
    background: #fff;
    margin: 6px auto;
    transition: all .3s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.MenuIcon:active,
.MenuIcon:hover,
.MenuIcon:focus{
    outline: none;
    background-color: transparent;
    box-shadow: none !important;
    border: 1px solid var(--MainColor);
}
@media(max-width:1440px){
    .navbarRight ul li{
        margin-left: 40px;
    }
}
@media(max-width:1366px){
    .navbarRight ul li{
        margin-left: 40px;
    }
}
@media(max-width:1024px){
    .ButtonPrimery{
        padding: 13px 18px;
    }
    .navbarRight ul{
        margin: 0 30px 0 0;
    }
    .navbarRight ul li{
        margin-left: 20px;
        font-size: 17px;
    }
}
@media(min-width:992px){
    .MenuIcon{
        display: none;
    }
}

@media(max-width:991px){
    .header{
        padding: 15px 0;
    }
    .navbarRight{
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        padding: 30px 35px;
        background-color: #1A1A1A;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transform: scaleY(0);
        transform-origin: top;
        transition: all .3s ease-in-out;
    }
    .navbarRight ul{
        display: block;
        width: 100%;
    }
    .navbarRight ul li{
        margin: 0 0 20px;
    }
    .MenuIcon{
        float: right;
    }
}