@viewport { width: 1920px; } 

:root{
  --MainColor:#FFBE25;
  --White:#fff;
  --greyColor: #979797;
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #111111;
  font-family: 'Poppins Regular';
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}
html::-webkit-scrollbar {
  width: 12px;
}
html::-webkit-scrollbar-thumb {
  background: #434343;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
}
html::-webkit-scrollbar-track {
  background: linear-gradient(90deg,#434343,#434343 1px,#111 0,#111);
}
::selection{
  background-color: #707070;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover,
a:focus{
  color: var(--MainColor);
}
button:hover,
button:focus,
button:active{
  box-shadow: none !important;
}
* {
  box-sizing: border-box;
}
svg{
  max-width: 100%;
}
.liner-bg {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  max-width: 1551px;
  margin: 0 auto;
  min-height: 100%;
  user-select: none;
  pointer-events: none;
}
.liner-bg .line-1 {
  border-right: 0.5px solid #707070;
  height: 100%;
  position: absolute;
  left: 53px;
  opacity: 0.2;
}
.liner-bg .line-5 {
  border-right: 0.5px solid #707070;
  height: 100%;
  position: absolute;
  right: 53px;
  opacity: 0.2;
}
.liner-bg .line-4 {
  border-right: 0.5px solid #707070;
  height: 100%;
  position: absolute;
  right: 25%;
  opacity: 0.2;
}
.liner-bg .line-2 {
  border-right: 0.5px solid #707070;
  height: 100%;
  position: absolute;
  left: 25%;
  opacity: 0.2;
}
.liner-bg .line-3 {
  border-right: 0.5px solid #707070;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.2;
  width: 1px;
}

main{
  position: relative;
  z-index: 99;
}
img{
  max-width: 100%;
}
ul li.slick-active{
  left: -30px;
}
.slick-arrow::before{
  content: none;
}

.BgText::after{
  content: attr(text);
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  flex: 0 0 100%;
  /* font-size: calc(32px + 16 * ((100vw - 568px) / (768 - 568))) */
}

.menu-open span:nth-child(1){
  transform: rotate(44deg) translate(3px, 2px);
}
.menu-open span:nth-child(3){
  transform: rotate(-45deg) translate(3px, -1px);
}
.menu-open span:nth-child(2n){
  display: none;
}
header .showMenu{
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
header.sticky{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: #1A1A1A;
  padding: 25px 0;
  transition: all .3s ease-in;
  box-shadow: 0px 1px 4px 0.5px #2c2c2c;
  animation: Sticky 1s;
  animation-timing-function: ease-in-out;
  -webkit-transform: translateY(0);
}
@keyframes Sticky{
  0% {
      margin-top: -200px;
  }

  100% {
      margin-top: 0;
  }
}

.container{
  max-width: 1470px;
}

@media(max-width:1440px){
  header .container{
    max-width: 1350px;
    padding: 0 15px;
  }
  .container{
    max-width: 1360px;
    padding: 0 15px;
  }
}
@media(max-width:1366px){
  header .container{
    max-width: 100%;
    padding: 0 15px;
  }
  .container{
    max-width: 1290px;
    padding: 0 15px;
  }
}
@media(max-width:1280px){
  header .container{
    max-width: 100%;
    padding: 0 15px;
  }
  .container{
    max-width: 1205px;
    padding: 0 15px;
  }
}
@media(max-width:1024px){
  header .container{
    max-width: 100%;
    padding: 0 15px;
  }
  .container{
    max-width: 950px;
    padding: 0 15px;
  }
}
@media(max-width:991px){
  header .container{
    max-width: 100%;
    padding: 0 15px;
  }
}
@media(max-width:768px){
  .liner-bg .line-1{
    left: 15px;
  }
  .liner-bg .line-5{
    right: 15px;
  }
  header .container{
    max-width: 100%;
    padding: 0 15px;
  }
  .container{
    max-width: 764px;
    padding: 0 15px;
  }
}
@media(max-width:575px){
  header .container{
    max-width: 100%;
    padding: 0 15px;
  }
  
}