.ContactUs{
    padding: 150px 0 100px;
    background-color: var(--MainColor);
}

.mainTitleWrap {
    position: relative;
    margin-bottom: 130px;
}

.mainTitle{
    font-family: 'Poppins SemiBold';
    margin-bottom: 15px;
    line-height: 1;
    font-size: 47px;
    color: #000;
}

.subTitle{
    font-family: 'Poppins Regular';
    font-size: 28px;
    margin: 0;
    color: #000;
}

.ContectBgText{
    fill: #00000000;
    stroke: #3d3d3d;
    stroke-width: .4 
}

.BgText{
    position: absolute;
    left: 0;
    right: 0;
    top: 34%;
    transform: translateY(-50%);
    z-index:0;
    user-select: none;
    pointer-events: none;
}

.AddressInfo{

}

.contect:not(:last-child){
    margin-bottom: 55px;
}

.contectTitle{
    font-size: 36px;
    font-family: 'Poppins SemiBold';
    color: #000;
}

.contectText{
    font-size: 17px;
    color: #000;
    margin-bottom: 10px;
}

.formControls{
    margin: 15px 0;
}


.formControls textarea{
    display: block;
    width: 100%;
    background-color: #111111;
    border: none;
    outline: none;
    border-radius: 9px;
    padding: 20px;
    color: #fff;
}
.formControls input{
    display: block;
    height: 60px;
    width: 100%;
    background-color: #111111;
    border: none;
    outline: none;
    border-radius: 9px;
    padding: 0 20px;
    color: #fff;
}

.formControls textarea::placeholder,
.formControls input::placeholder{
    color: #fff;
}

.SubmitBtn{
    background-color: #111111;
    color: #fff;
    border-color: #111111;
    padding: 10px 20px;
    position: relative;
    overflow: hidden;
    z-index: 2;
}
.SubmitBtn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    /* border-radius: 25px; */
    bottom: 0;
    background: #242424;
    transform: scaleY(0);
    transform-origin: 50% 0;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.SubmitBtn:hover:before{
    transform: scaleY(1);
    bottom: 0;
}

@media(min-width:992px) and (max-width:1024px){
    .contect:not(:last-child){
        margin-bottom: 50px;
    }
}
@media(max-width:991px){
    .BgText{
        top: 50%;
        height: auto;
    }
    .mainTitleWrap{
        margin-bottom: 80px;
    }
    .mainTitle{
        font-size: 40px;
    }
    .subTitle{
        font-size: 25px;
    }
    .contectTitle{
        font-size: 28px;
    }
    .contectText{
        font-size: 16px;
    }
    .formControls{
        margin: 10px 0;
    }
}

@media(max-width:575px){
    .ContactUs{
        padding: 50px 0;
    }
    .mainTitle{
        font-size: 20px;
    }
    .subTitle{
        font-size: 16px;
    }
    .mainTitleWrap{
        margin-bottom: 30px;
    }
    .contectTitle{
        font-size: 20px;
    }
    .contectText{
        font-size: 14px;
    }
    .ContactUs .contect{
        margin-bottom: 40px;
    }
    .formControls{
        margin: 10px 0;
    }
    .formControls input{
        height: 50px;
        font-size: 15px;
    }
    .formControls textarea{
        font-size: 15px;
    }
}