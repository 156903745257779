.OurTechnology{
    padding: 250px 0;
}

.mainTitleWrap {
    position: relative;
    margin-bottom: 80px;
}

.smallTitle{
    letter-spacing: 21px;
    text-transform: uppercase;
    color: var(--MainColor);
    font-family: 'Poppins SemiBold';
    margin-bottom: 30px;
    font-size: 22px;
}

.mainTitle{
    font-family: 'Poppins SemiBold';
    margin-bottom: 15px;
    /* line-height: 1; */
    font-size: 47px;
}

.subTitle{
    font-family: 'Poppins Regular';
    font-size: 28px;
    margin: 0;
}

.BlogBox{
    background-color: #090909;
    border: 1px solid #191919;
    margin: 0 -1px;
}

.ClientLogos{
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.BoxWrap{
    position: relative;
}

.ShapeBorder{
    border-right: 1px solid #131313;
}

.ShapeBorder:nth-child(3n+1){
    border-top: 1px solid #131313;
}

.ShapeBorder:nth-child(3n+2){
    border-top: 1px solid #131313;
}

.ShapeBorder:nth-child(3n+3){
    border-top: 1px solid #131313;
}

.ClientHover{
    position: absolute;
    inset: 0 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #090909;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    cursor: pointer;
    font-size: 35px;
}

.BoxWrap:hover .ClientHover{
    opacity: 1;
    visibility: visible;
}
@media(max-width:1024px){
    .OurTechnology{
        padding: 200px 0;
    }
}
@media(max-width:991px){
    .ClientLogos{
        height: 250px;
    }
    .ClientLogos img{
        width: 120px;
    }
}
@media(max-width:767px){
    .OurTechnology{
        padding: 150px 0;
    }
    .BlogBox .ShapeBorder{
        border: 1px solid #131313;
    }
    .mainTitleWrap{
        margin-bottom: 50px;
    }
}
@media(max-width:575px){
    .OurTechnology{
        padding: 100px 0;
    }
    .smallTitle{
        letter-spacing: 10px;
        font-size: 18px;
    }
    .mainTitle{
        font-size: 27px;
    }
    .mainTitleWrap{
        margin-bottom: 40px;
    }
    .ClientLogos{
        height: 150px;
    }
    .ClientLogos img{
        width: 100px;
    }
    .BlogBox .ShapeBorder{
        border: none;
        border-bottom: 1px solid #131313;
    }
}