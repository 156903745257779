.OurServices {
    padding: 165px 0;
}

.mainTitleWrap {
    position: relative;
    margin-bottom: 250px;
}


.serviceText {
    fill: #111111;
    stroke: #464646;
    stroke-width: .4
}
.ServiceTextIcon{
    position: absolute;
    left: 0;
    right: 0;
    top: 47%;
    transform: translateY(-50%);
    z-index: -1;
    user-select: none;
    pointer-events: none;
}

.smallTitle{
    letter-spacing: 21px;
    text-transform: uppercase;
    color: var(--MainColor);
    font-family: 'Poppins SemiBold';
    margin-bottom: 30px;
    font-size: 22px;
}

.mainTitle{
    font-family: 'Poppins SemiBold';
    margin-bottom: 15px;
    line-height: 1;
    font-size: 47px;
}

.subTitle{
    font-family: 'Poppins Regular';
    font-size: 28px;
    margin: 0;
}

.tabWrapper{
    position: relative;
}

.Tabs{
    margin: 0;
    padding: 0;
}

.Tabs li{
    display: block;
    margin-bottom: 20px;
    font-size: 37px;
    font-family: 'Poppins Light';
    cursor: pointer;
}
.Tabs li:last-child{
    margin-bottom: 0;
}
.Tabs .tabsActive{
    color: var(--MainColor);
    font-family: 'Poppins Medium';
}

.tabContentBox{
    background-color: hsl(0deg 0% 14% / 60%);
    min-height: 100%;
    position: relative;
    display: none;
}

.tabContent{
    padding: 35px 45px;
    overflow: hidden;
}

.tabContent h4{
    font-size: 59px;
    font-family: 'Poppins Medium';
    margin-bottom: 20px;
}

.tabContent p{
    font-size: 31px;
    color: #979797;
}

.tabContent ul{
    margin: 0;
    margin-top: 40px;
    padding-left: 22px;
}

.tabContent ul li{
    margin-bottom: 20px;
    font-family: 'Poppins SemiBold';
}

.tabContentImage{
    position: absolute;
    bottom: 0;
    right: 10px;
}

.tabContentImage img{
    width: 400px;
}

.tabContentBoxShow{
    display: block;
}
@media(max-width:1440px){
    .mainTitleWrap{
        margin-bottom: 150px;
    }
}
@media(max-width:1024px){
    .OurServices{
        padding: 125px 0;
    }
    .mainTitleWrap{
        margin-bottom: 100px;
    }
    .Tabs li{
        font-size: 26px;
    }
    .tabContent{
        padding: 30px 30px;
    }
    .tabContent h4{
        font-size: 45px;
    }
    .tabContent p{
        font-size: 22px;
    }
    .tabContentImage{
        right: 0;
    }
    .tabContentImage img{
        width: 370px;
    }
}
@media(max-width:991px){
    .OurServices{
        padding: 115px 0;
    }
    .mainTitleWrap{
        margin-bottom: 70px;
    }
    .mainTitle{
        font-size: 40px;
        margin-bottom: 10px;
    }
    .subTitle{
        font-size: 21px;
    }
    .smallTitle{
        margin-bottom: 20px;
    }
    .ServiceTextIcon{
        height: auto;
        filter: drop-shadow(0px 0px 0px #333);
    }
    .Tabs li{
        font-size: 22px;
    }
    .tabContent{
        padding: 15px 25px;
    }
    .tabContent h4{
        font-size: 35px;
    }
    .tabContent p{
        font-size: 18px;
    }
    .tabContent ul{
        margin: 0;
    }
    .tabContentImage{
        right: 0;
    }
    .tabContentImage img{
        width: 270px;
    }
}
@media(max-width:767px){
    .Tabs li{
        font-size: 17px;
    }
    .tabContent h4{
        font-size: 27px;
    }
    .tabContent p{
        font-size: 15px;
    }
    .tabContent ul li{
        margin-bottom: 5px;
        font-size: 15px;
    }
    .tabContentImage img{
        width: 210px;
    }
}
@media(max-width:575px){
    .OurServices {
        padding: 100px 0;
    }
    .smallTitle{
        margin-bottom: 10px;
        font-size: 18px;
    }
    .mainTitle{
        font-size: 25px;
    }
    .subTitle{
        font-size: 14px;
    }
    .mainTitleWrap{
        margin-bottom: 50px;
    }
    .Tabs{
        display: flex;
        overflow-x: scroll;
        margin-bottom: 25px;
        padding-bottom: 10px;
    }
    .Tabs li{
        font-size: 18px;
        flex-shrink: 1;
        margin-bottom: 0;
        white-space: nowrap;
        margin-right: 20px;
    }
    .tabContent{
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
    }
    .tabContent h4{
        font-size: 30px;
    }
    .tabContent p{
        font-size: 16px;
    }
    .tabContentImage{
        position: unset;
        order: 1;
        text-align: center;
    }
    .tabContentImage img{
        width: 200px;

    }
    .tabContent ul{
        order: 2;
    }
}