.OurBlogs{
    padding: 350px 0 50px;
}

.MainTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 110px;
}
.Title{
    letter-spacing: 21px;
    text-transform: uppercase;
    color: var(--MainColor);
    font-family: 'Poppins SemiBold';
    font-size: 22px;
    margin: 0;
}

.ShowMore h4{
    margin: 0;
    font-size: 22px;
    letter-spacing: 18px;
    color: #979797;
    font-family: 'Poppins SemiBold';
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.BlogPostWrap{
    text-align: right;
}
.BlogPostWrap:last-child{
    margin-top: -110px;
}

.BlogPost{
    margin-bottom: 110px;
    display: inline-block;
    text-align: left;
}

.BlogPost h4{
    font-size: 47px;
    font-family: 'Poppins SemiBold';
    margin: 20px 0;
}
@media(max-width:1280px){
    .OurBlogs{
        padding: 200px 0 50px;
    }
}
@media(max-width:1024px){
    
    .BlogPost h4{
        font-size: 35px;
    }
}
@media(max-width:991px){
    .OurBlogs{
        padding: 150px 0 50px;
    }
    .Title{
        letter-spacing: 12px;
    }
    .ShowMore h4{
        letter-spacing: 12px;
        font-size: 18px;
    }
    .MainTitle{
        margin-bottom: 60px;
    }
    .BlogPostWrapLeft:first-child{
        padding-top: 60px !important;
    }
    .BlogPostWrap:last-child{
        margin-top: -60px;
    }
    .BlogPostWrapLeft:first-child .BlogPost{
        margin-bottom: 80px;
    }
    .BlogPost{
        margin-bottom: 50px;
    }
}
@media(max-width:767px){
    .OurBlogs{
        padding: 100px 0;
    }
    .MainTitle{
        margin-bottom: 40px;
    }
    .Title{
        letter-spacing: 10px;
    }
    .ShowMore h4{
        font-size: 16px;
        letter-spacing: 7px;
    }
    .BlogPostWrapLeft:first-child{
        padding-top: 50px !important;
    }
    .BlogPostWrap:last-child{
        margin-top: -50px;
    }
}
@media(max-width:575px){
    .OurBlogs{
        padding: 100px 0;
    }
    .MainTitle{
        /* display: block; */
        margin-bottom: 10px;
    }
    .Title{
        letter-spacing: 4px;
        font-size: 17px;
    }
    .ShowMore h4{
        font-size: 14px;
        letter-spacing: 5px;
    }
    .BlogPostWrapLeft:first-child{
        padding-top: 40px !important;
    }
    .BlogPost h4{
        margin: 15px 0;
        font-size: 30px;
    }
    .BlogPost{
        margin-bottom: 50px;
    }
    .BlogPostWrap:last-child{
        margin-top: 0;
    }
    .BlogPostWrap:last-child .BlogPost{
        margin-bottom: 0;
    }
}