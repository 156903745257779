.serviceContainer{
    background: #1A1A1A 0% 0% no-repeat padding-box;
    padding: 175px 0px;
}
.serviceInfo{
    display: flex;
    flex-direction: column;
}
.serviceHeader{
    letter-spacing: 21px;
    text-transform: uppercase;
    color: var(--MainColor);
    font-family: 'Poppins SemiBold';
    margin-bottom: 55px;
}
.serviceTitle{
    font-family: 'Poppins SemiBold';
    margin-bottom: 40px;
    line-height: normal;
    font-size: 45px;
}
.serviceDetail{
    color: var(--greyColor);
    font-size: 25px;
}
.serviceAboutBtn{
    background: transparent;
    border: 1px solid #D8D8D8;
    border-radius: 24px;
    padding: 10px 40px;
    font-family: 'Poppins Medium';
    position: relative;
    overflow: hidden;
    z-index: 2;
}
.aboutBtnWraper{
    /* text-align: right; */
    padding-top: 15px;
}
.serviceAboutBtn::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    /* border-radius: 25px; */
    bottom: 0;
    background: var(--MainColor);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
.serviceAboutBtn:hover::before{
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    bottom: 0;
}
.serviceAboutBtn:hover{
    background-color: transparent;
    color: #000;
    border-color: var(--MainColor);
}
@media(max-width:1024px){
    .serviceContainer{
        padding: 100px 0px;
    }
    .serviceTitle{
        font-size: 40px;
    }
    .serviceDetail{
        font-size: 20px;
    }
}
@media(max-width:768px){
    .serviceContainer{
        padding: 100px 0px;
    }
    .serviceTitle{
        font-size: 35px;
    }

}
@media(max-width:575px){
    .serviceContainer{
        padding: 65px 0px;
    }
    .serviceHeader{
        margin-bottom: 30px;
        letter-spacing: 10px;
    }
    .serviceTitle{
        font-size: 17px;
        margin-bottom: 20px;
    }
    .serviceDetail{
        font-size: 14px;
    }
}