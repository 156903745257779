.Mainbanner{
  padding-top: 165px;
}
.Mainbanner span {
    display: inline-block;
    vertical-align: middle;
}
.bannerContainer{
    max-width: 1600px;
}
.MainbannerContent{
    padding-left: 0;
}
.MainbannerContent h2{
    font-size: 225px;
    font-family: 'Poppins SemiBold';
    color: #111111;
    filter: drop-shadow(0px 0px 0px #707070);
    pointer-events: none;
    user-select: none;
    margin: 0;
    line-height: .8;
}
.MainbannerContent h3{
    font-size: 43px;
    margin-top: -75px;
    z-index: 99;
    position: relative;
    line-height: 1;
    margin-bottom: 27px;
}
.MainbannerContent h4{
    font-size: 48px;
    font-family: 'Poppins SemiBold';
}
.MainbannerContent h5{
    font-size: 43px;
    margin: 0;
}
@media(max-width:1440px){
    .bannerContainer{
        max-width: 1360px;
    }
}
@media(max-width:1024px){
    .Mainbanner{
        padding-top: 100px;
    }
    .MainbannerContent h2{
        font-size: 165px;
    }
    .MainbannerContent h3{
        font-size: 38px;
    }
    .MainbannerContent h4{
        font-size: 40px;
    }
    .MainbannerContent h5{
        font-size: 35px;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .bannerContainer{
        max-width: 948px;
    }
}
@media(max-width:991px){
    .Mainbanner{
        padding-top: 65px;
    }
    .MainbannerContent h2{
        font-size: 125px;
        filter: drop-shadow(0px 0px 0.7px #707070);
    }
    .MainbannerContent h4{
        font-size: 35px;
    }
    .MainbannerContent h3{
        margin-top: -60px;
        margin-bottom: 15px;
    }
    .MainbannerContent h5{
        font-size: 30px;
    }
}
@media(max-width:575px){
    .MainbannerContent h2{
        filter: drop-shadow(0px 0px 0.7px #707070);
        font-size: 155px;
    }
    .MainbannerContent h3{
        margin-top: -55px;
        margin-bottom: 15px;
    }
    .MainbannerContent h4{
        font-size: 30px;
    }
    .MainbannerContent h5{
        font-size: 25px;
    }
    .mainBannerWrap{
        margin-top: 40px;
    }
}