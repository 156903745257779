

.GetInTouchBlock{
    background-color: #18181A;
    border-radius: 12px;
    padding: 125px 160px;
}

.GetInTouchTitle{
    font-size: 24px;
    font-family: 'Poppins SemiBold';
    line-height: normal;
}

.GetInTouchLabel{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: #979797;
    font-size: 20px;
    font-family: 'Poppins Light';
}

.GetInTouchText{
    font-size: 22px;
    margin-bottom: 0;
}
@media(max-width:1024px){
    .GetInTouchBlock{
        padding: 85px 50px;
    }
    .GetInTouchTitle{
        font-size: 22px;
    }
}
@media(max-width:991px){
    .GetInTouchBlock{
        padding: 60px 30px;
    }
    .GetInTouchTitle{
        font-size: 20px;
    }
    .GetInTouchLabel{
        margin-bottom: 4px;
    }
    .GetInTouchText{
        font-size: 20px;
    }
}
@media(max-width:767px){
    .GetInTouchBlock{
        padding: 25px 30px;
    }
    .Info{
        margin-bottom: 20px;
    }
}
@media(max-width:575px){
    .GetInTouchBlock{
        padding: 30px 30px;
    }
    .GetInTouchTitle{
        font-size: 18px;
        margin-bottom: 0;
    }
    .Info:not(:last-child){
        margin-bottom: 25px;
    }
    .GetInTouchText{
        font-size: 18px;
    }
    .GetInTouchLabel{
        font-size: 16px;
    }
    .GetInTouchLabel img{
        height: 15px !important;
    }
}